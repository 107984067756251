<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,
} from "vuelidate/lib/validators";

import { accountsService } from '../../../services/accounts.service';

const initForm = { accountName: "", accountDescription: "", accountStatus: "active"};

export default {
  page: {
    title: "Account Management ",
    meta: [{ accountName: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Accounts",
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Accounts",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { key: "accountName", sortable: true },
        { key: "accountDescription", sortable: true },
        { key: "accountStatus", sortable: true },
        // { key: "actions"}
      ],

      form: {
        accountName: "",
        accountDescription: "",
        accountStatus: "active"
      },

      editForm: {
        accountName: null,
        accountDescription: null,
        accountStatus:"active"
      },

      submitted: false,
      submitform: false,
      submit: false,
      showform: false
    };
  },

  validations: {
    form: {
      accountName: { required },
      accountStatus: { required }
    },
  },

  created() {
    this.loadAllAccounts()
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      } else {
          const {form} = this;
          const accountForm = {
                "operation" : "suspenseCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          };
          
          accountsService.createAccount(accountForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllAccounts()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    storeState(account){
      console.log(account);
    },

    editShop() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submit = false;
        return;
      } else {
          const {editForm} = this;
          const accountForm = {
                "operation" : "shopCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : editForm
          };
          
          accountsService.createAccount(accountForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllAccounts()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.editForm = Object.assign({}, initForm);
            } else {
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    async loadAllAccounts() {
      const rowsToBeRemoved = ["1","2","3","4","5"];
        try {
          await accountsService.getAllSuspenseAccounts().then(response=>{
            if(response.responseBody.length>0){

              rowsToBeRemoved.forEach(element => {
                const indexOfObject = response.responseBody.findIndex(object => {
                  return object.id == element;
                });
                response.responseBody.splice(indexOfObject, 1);
              });
              
                this.tableData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
                        <b-modal id="modal-standard" title="Edit Branch" title-class="font-18" hide-footer>
                      <div class="card-body">
                        <form class="needs-registration" @submit.prevent="editShop">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom01">Account Name</label>
                                <input
                                  id="validationCustom01"
                                  v-model="editForm.accountName"
                                  type="text"
                                  class="form-control"
                                  placeholder="Account Name"
                                />
                              </div>
                            </div>
                            </div>
                            <div class="row">
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom04">Account Description</label>
                                <input
                                  id="validationCustom03"
                                  v-model="editForm.accountDescription"
                                  type="text"
                                  class="form-control"
                                  placeholder="Account Description"
                                />
                              </div>
                            </div>
                          </div>
                           <div class="spinner" v-if="submitted">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
                          <button v-if="!submitted" class="btn btn-primary" type="submit">Save changes</button>
                        </form>
                      </div>
                  </b-modal>
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
              
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row)"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >

                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>

                </a>
                <a
                  @click="row.toggleDetails"
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Disable"
                >
                  <i class="mdi mdi-cancel font-size-18"></i>
                </a>

              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
        </b-alert>
          <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showform = true" v-if="!showform">
            <i class="mdi mdi-plus mr-2"></i> Create Suspense Account
          </a>

        <div class="card" v-if="showform">
       <div class="card-header">
        <h5>Create Suspense Account</h5>
       </div>
          <div class="card-body">
            
            <form class="needs-registration" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom01">Account Name</label>
                    <input
                      id="validationCustom01"
                      v-model="form.accountName"
                      type="text"
                      class="form-control"
                      placeholder="Account Name"
                      :class="{ 'is-invalid': submitted && $v.form.accountName.$error }"
                    />
                    <div v-if="submitted && $v.form.accountName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.accountName.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom04">Account Description</label>
                    <input
                      id="validationCustom03"
                      v-model="form.accountDescription"
                      type="text"
                      class="form-control"
                      placeholder="Account Description"
                      :class="{ 'is-invalid': submitted && $v.form.accountDescription.$error }"
                    />
                    <div v-if="submitted && $v.form.accountDescription.$error" class="invalid-feedback">
                      <span v-if="!$v.form.accountDescription.required">This value is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-md-6" v-if="!submitted">
                      <button class="btn btn-primary" type="submit">Create Account</button>
                  </div>
                  <div class="col-md-6">
                    <div class="spinner" v-if="submitted">
                        <i class="ri-loader-line spin-icon"></i>
                      </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>